import '../../styles/index.scss';

import React from 'react';

import { graphql } from 'gatsby';

import ArticlesBrowser from '../../components/ArticlesBrowser/ArticlesBrowser';
import GradientBanner from '../../components/Decorations/GradientBanner/GradientBanner';
import Layout from '../../components/Layout/Layout';
import BlogSEO from '../../components/Seo/BlogSEO';
import MainTitle from '../../components/Title/MainTitle/MainTitle';

import * as css from './blog.module.scss';

const IndexPage = ({ data }: { data: any }) => {
  const { imageUrl, allBlogPost, productImage } = data;
  const imageOg = imageUrl.edges[0]?.node.fluid.src;
  const productImageSrc = productImage.edges[0]?.node.fluid.src;
  return (
    <Layout>
      <BlogSEO imageUrl={imageOg} productImage={productImageSrc} />
      <div className="container-flex">
        <div style={{ marginTop: '50px' }} className="container">
          <MainTitle
            titleId="blog.mainTitle.title"
            subtitleId="blog.mainTitle.subtitle"
          />
          <div className={css.articlesContainer}>
            <ArticlesBrowser articles={allBlogPost.nodes} articlePerPage={3} />
          </div>
        </div>
      </div>
      <div className={css.gradientBannerContainer}>
        <GradientBanner />
      </div>
    </Layout>
  );
};
export default IndexPage;

//export page query
export const query = graphql`
  query {
    allBlogPost: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          language
          title
          tags
          date
          slug
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    imageUrl: allImageSharp(
      filter: { fluid: { originalName: { eq: "App1.png" } } }
    ) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
    productImage: allImageSharp(
      filter: { fluid: { originalName: { eq: "App1.png" } } }
    ) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
  }
`;
