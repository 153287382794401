import React, { useEffect, useState } from 'react';

import Button from '../Button/Button';
import ArrowTip from '../Svgs/ArrowTip/ArrowTip';

import * as css from './PageSelector.module.scss';

const THREE_DOTS = '…';

export type PageSelectorProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  numberOfPages: number;
};

const PageSelector = ({
  currentPage,
  setCurrentPage,
  numberOfPages,
}: PageSelectorProps) => {
  const handleNext = () => {
    if (currentPage < numberOfPages - 1) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleCustom = (page: number) => {
    if (page <= numberOfPages && page >= 0) {
      setCurrentPage(page - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const [pagesToDisplay, setPagesToDisplay] = useState<
    (number | typeof THREE_DOTS)[]
  >([]);

  useEffect(() => {
    const tempPages: (number | typeof THREE_DOTS)[] = [];
    if (numberOfPages <= 6) {
      for (let i = 1; i <= numberOfPages; i++) {
        tempPages.push(i);
      }
      setPagesToDisplay(tempPages);
      return;
    }

    if (currentPage + 1 <= 3) {
      for (let i = 1; i <= 5; i++) {
        tempPages.push(i);
      }
      tempPages.push(THREE_DOTS);
      tempPages.push(numberOfPages);
      setPagesToDisplay(tempPages);
      return;
    }

    if (currentPage >= numberOfPages - 3) {
      tempPages.push(1);
      tempPages.push(THREE_DOTS);
      for (let i = numberOfPages - 4; i <= numberOfPages; i++) {
        tempPages.push(i);
      }
      setPagesToDisplay(tempPages);
      return;
    }

    tempPages.push(1);
    tempPages.push(THREE_DOTS);
    for (let i = currentPage; i <= currentPage + 2; i++) {
      tempPages.push(i);
    }
    tempPages.push(THREE_DOTS);
    tempPages.push(numberOfPages);
    setPagesToDisplay(tempPages);
  }, [currentPage, numberOfPages]);

  return (
    <div className={css.container}>
      <Button
        titleId={null}
        variant="secondary"
        icon={<ArrowTip direction="left" height={16} variant="secondary" />}
        onClick={handlePrevious}
      />
      <div className={css.pagesContainer}>
        {pagesToDisplay.map(page => (
          <div
            onClick={() => {
              if (page !== THREE_DOTS) handleCustom(page);
            }}
            className={
              page !== THREE_DOTS
                ? page !== currentPage + 1
                  ? `${css.element}`
                  : `${css.element} ${css.active}`
                : ''
            }
            key={Math.random()}
          >
            {page}
          </div>
        ))}
      </div>
      <Button
        titleId={null}
        variant="secondary"
        icon={<ArrowTip direction="right" height={16} variant="secondary" />}
        onClick={handleNext}
      />
    </div>
  );
};

export default PageSelector;
