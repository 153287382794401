import React, { useEffect, useState } from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';

import type ArticlePreviewType from '../../types/ArticlePreview';
import BlogCard from '../Cards/BlogCard/BlogCard';
import PageSelector from '../PageSelector/PageSelector';

import * as css from './ArticlesBrowser.module.scss';

export type ArticlesBrowserProps = {
  articles: ArticlePreviewType[];
  articlePerPage: number;
};

const ArticlesBrowser = ({
  articles,
  articlePerPage,
}: ArticlesBrowserProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [blogPosts, setBlogPosts] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setBlogPosts(
      articles.filter(function (article) {
        return article.frontmatter.language === intl.locale;
      })
    );
  }, [articles, articlePerPage, intl.locale]);

  useEffect(() => {
    setNumberOfPages(Math.ceil(blogPosts.length / articlePerPage));
  }, [blogPosts]);
  return (
    <div className={css.container}>
      {blogPosts
        .slice(
          articlePerPage * currentPage,
          articlePerPage * currentPage + articlePerPage
        )
        .map((article: ArticlePreviewType) => (
          <div key={article.frontmatter.slug} className={css.article}>
            <BlogCard
              titleId={article.frontmatter.title}
              subtitleId={article.frontmatter.tags}
              textId={article.frontmatter.abstract}
              link={`/blog/${article.frontmatter.slug}`}
              image={
                article.frontmatter.thumb
                  ? article.frontmatter.thumb.childImageSharp.fluid
                  : null
              }
            />
          </div>
        ))}
      {numberOfPages > 1 && (
        <div className={css.pageSelectorContainer}>
          <PageSelector
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numberOfPages={numberOfPages}
          />
        </div>
      )}
    </div>
  );
};

export default ArticlesBrowser;
